<template>
  <div :class="{ contain: true, mobile: isMobile }">
    <div class="title mb-3 mt-5">{{ $t("headerUserCenterNavMyAsset") }}</div>
    <div v-if="empty" class="empty">{{$t('EmptyHere')}}</div>
    <div class="asset d-flex flex-row">
         <div class="totalAsset d-flex flex-column">
            <span class="title">{{$t('AvailableAssets')}}</span>
            <span class="price">$ {{availableAssets}}</span>
        </div>

    </div>
    <div class="d-flex justify-space-between mt-9 mb-3">
        <div class="title ">{{$t('TitleAssets')}}</div>
         <img  :class="[rotate?'go':'']" class="refresh" src="@/assets/icons/icon_refresh.png"  @click="refresh" />
    </div>
  
       <v-data-iterator
        hide-default-footer
        :items="desserts"
        no-data-text=""
        style="margin-bottom:125px"
        
      >
        <template v-slot:header>
          <div
            class="table_font d-flex justify-space-around py-2 px-lg-10 elevation-0"
            style="border-radius: 5px 5px 0px 0px;background-color: #766983;height: 51px;"
          >
            <div style="width: 50%;color: #ffffff;padding-top: 6px;">{{$t('AssetChain')}}</div>
            <div style="width: 15%;color: #ffffff;padding-top: 6px;">{{$t('balanceCount')}}</div>
            <div style="width: 20%;color: #ffffff;padding-top: 6px;">{{$t('IBCDeposit')}}</div>
            <div style="width: 15%;color: #ffffff;padding-top: 6px;">{{$t('IBCWithdraw')}}</div>
          </div>
        </template>
        <template v-slot:default="{ items }">
          <div
            class="table_item_font d-flex justify-space-around  py-2 px-lg-10 elevation-0"
            style="background-color: #ffffff;border: solid 1px #e1e1e1;"
            v-for="(item, index) in items"
            :key="index"
          >
            <div class="overflow item_font" style="width: 50%;margin-bottom:11px">
              <img  
              :src="
                require('../../assets/icons/' +
                  item.formImage +
                  '.png')
              "
          alt=""
          width="45px"
          height="45px"
        />
        <span style="margin-top:-39px;display:block;    margin-left: 48px;">{{item.formname}}</span>
            </div>
            <div class="overflow item_font" style="width: 15%;margin-top: 11px;">
              {{ item.balance }}
            </div>
            <div  v-if="item.formname != 'IRIS'" class="overflow d-flex flex-row" style="width: 20%;margin-top: 11px;" >
              
              <span style="color: #7800f4;cursor:pointer;font-family: Helvetica;font-size: 15px;" @click="clickDeposit(item.formname)">{{$t('Deposit')}} ></span>
            </div>
            <div  v-else class="overflow d-flex flex-row" style="width: 20%;color: #7800f4;font-family: Helvetica;font-size: 15px;">
             
            </div>
            <div v-if="item.formname != 'IRIS'" class="overflow item_font item_fonts" style="width: 15%;margin-top: 11px;" >
              
              <span style="color: #7800f4;cursor:pointer;font-family: Helvetica;font-size: 15px;" @click="clickWithdraw(item.formname)">{{$t('Withdraw')}} ></span>
            </div>
             <div v-else class="overflow item_font item_fonts" style="width: 15%;color: #7800f4;font-family: Helvetica;font-size: 15px;" >
             
            </div>
          </div>
        </template>
      </v-data-iterator>
    <loading :isShowLoading="isShowLoading" ></loading>
    
     <!-- IBC兑换 -->
    <v-dialog v-model="assetdeposit" v-if="assetdeposit">
      <assetDeposit
        @getOpen="openDeposit"
        :assetType="assetType"
        :assetChain="assetChain"

      ></assetDeposit>
    </v-dialog>

     <v-dialog v-model="assetdeclaration" v-if="assetdeclaration">
      <assetDeclaration
        @getOpen="openDeclaration"
      

      ></assetDeclaration>
    </v-dialog>
    
  </div>
</template>

<script>

  import Loading from "@/components/loading.vue";

import rightIcon from "@/assets/icons/icon_r.png";
import AssetDeposit from "@/components/popup/assetDeposit.vue";
import AssetDeclaration from "@/components/popup/assetDeclaration.vue";
import {init as keplrInit, getBalances} from "../../keplr/wallet";
import {getAddress } from "../../keplr/wallet";
let count = 0;
let timeout = 0;

export default {
  components: { Loading,AssetDeposit,AssetDeclaration},
  data: () => ({
    list: [
      // {
      //     id:1,
      //     title:'#10',
      //     price:'100.236',
      //     num:'3',
      //     time:'2021.09.12  10:00:00',
      //     isLucky:false,
      // },
      // {
      //     id:2,
      //     title:'#20',
      //     price:'100.246835451936',
      //     num:'8',
      //     time:'2029.09.12  10:00:00',
      //     isLucky:true,
      // },
      // {
      //     id:3,
      //     title:'#130',
      //     price:'1009846512346.236',
      //     num:'19',
      //     time:'2021.02.12  10:00:00',
      //     isLucky:true,
      // },
    ],
      rotate:false,
    rightIcon,
    pageNumber: 0,
    pageSize: 10,
    openShare: false,
    share_id: 0,
    isCopy: false,
    isShowLoading: false,
    isLoading:false,
    openlottery:false,
    luckyId:'',
    empty: false,
    assetdeposit:false,
    assetdeclaration:false,
    assetType:'',
    assetChain:'',
    accountBalanceInfo:[],
    desserts: [
      {
        formname:"IRIS",
        balance:"0",
        formImage:"chain_iris_w"
      },
      // {
      //   formname:"USDC",
      //   balance:"0",
      //   formImage:"chain_usdc_w"
      // },
      {
        formname:"ATOM",
        balance:"0",
        formImage:"chain_atom_w"
      },
    ],
    availableAssets:''
  }),
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  async mounted() {
  this.init();


  },
  methods: {
     async init(){
         let usd_price = localStorage.getItem("KEY_USD_PRICE");
         let IrisTokenAddress = JSON.parse(localStorage.getItem("key_tokenAddress"));
         if(!IrisTokenAddress){
          let IrisObj = await getAddress(1);
        //  let USDCObj = await getAddress(3);
         let AtomObj = await getAddress(2);
          //  获取钱包地址
         let IrisAddress = IrisObj.address;
    // let USDCAddress = USDCObj.address;
    let AtomAddress = AtomObj.address;
         let IrisTokenAddress = {
           IrisAddress:IrisAddress,
          //  USDCAddress:USDCAddress,
           AtomAddress:AtomAddress,
         }
         console.log("wxl --- getAddress",IrisObj,AtomObj)
        localStorage.setItem("key_tokenAddress", JSON.stringify(IrisTokenAddress));
         }
   keplrInit(1);
    this.accountBalance = await getBalances();
    let aviIris = 0,aviUsdc=0,aviAtom=0;
    if(this.accountBalance.balancesList){
      for(let i=0;i<this.accountBalance.balancesList.length;i++)
      {
        if(this.accountBalance.balancesList[i].denom == "uiris"){
          this.desserts[0].balance = this.accountBalance.balancesList[i].amount/1000000   
          aviIris = Number((this.desserts[0].balance * JSON.parse(usd_price).IrisPrice).toFixed(4));  
        }else if(this.accountBalance.balancesList[i].denom == "ibc/27394FB092D2ECCD56123C74F36E4C1F926001CEADA9CA97EA622B25F41E5EB2")
        {
          this.desserts[1].balance = this.accountBalance.balancesList[i].amount/1000000    
           aviAtom = Number((this.desserts[1].balance * JSON.parse(usd_price).AtomPrice).toFixed(4))    
        }
        // else if(this.accountBalance.balancesList[i].denom == "ibc/A5A5EBF3B7A180FBA2A3CE133153F5B904AA8B085691F66A15BE8156B6722652")
        // {
        //    this.desserts[1].balance = this.accountBalance.balancesList[i].amount/1000000   
        //     aviUsdc = Number((this.desserts[1].balance).toFixed(4))  
        // }
      }
    }
    console.log("wxl ---  keplrInit",this.accountBalance)
      this.availableAssets = (aviIris + aviUsdc + aviAtom).toFixed(4)
     },
      refresh() {
         this.rotate=true;
         setTimeout(() => { this.rotate=false }, 2000);
         this.pageNumber = 1;
         this.orders = [];
         this.init();
       },
      clickDeposit(formName){ 
        this.assetType = "Deposit"
        this.assetChain = formName
         this.assetdeposit = true
         clearTimeout(timeout);
        count = 100;
      },
      openDeposit(e) {
      this.assetdeposit = e;
       count = 0;
      this.refreshPageData();
    },
    openDeclaration(e){
      this.assetdeclaration = e
    },
      clickWithdraw(formName){
        this.assetType = "Withdraw" 
        this.assetChain = formName
          this.assetdeposit = true
      },
          refreshPageData() {
      timeout = setTimeout(async () => {
        await this.init();
        console.log("refreshPageData",count);
        if(count < 10) {
          this.refreshPageData();
          count++;
        }
      }, 3000);
    }
  },
};
</script>

<style lang="scss" scoped>
.contain {
   .empty {
	  width: 100%;
	  height: 500px;
	  display: flex;
	  justify-content: center;
	  align-items: center;
  }
  .refresh{
         height: 25px;
         width: 25px;
     
      }
   // .aa{
   //       transition: all 2s;
   //   }
      
      .go{
         transform:rotate(360deg);
         transition: all 2s;
         pointer-events: none;
         user-select: none;
      }
   
  .list {
    max-width: 1217px;
    height: 101px;
    background-image: linear-gradient(#ffffff, #ffffff),
      linear-gradient(#6f58d9, #6f58d9);
    background-blend-mode: normal, normal;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
    border-radius: 5px;
    padding: 24px 31px 29px;
    position:relative;
    .price {
      // max-width:468px;
      .pricetit {
        font-family:Helvetica;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #270645;
        margin-bottom: 0px;
      }
      img {
        width: 21px;
        height: 21px;
        display: block;
        margin-top: 4px;
        margin-right: 15px;
      }
      .pricenum {
       font-family:Helvetica;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #270645;
      }
    }
    .num {
      // max-width:190px;
      margin: 17px 0;
       cursor: pointer;
      position:relative;
      .pool {
        margin-left: -44px;
        position: absolute;
        margin-top: -4px;
      }
    }
    .time {
      margin: -5px 0;
    }
    .link {
      position:relative;
      margin: 15px 0;
       
      
    }
   .share {
          width: 166px;
          height: 161px;
          background-image: linear-gradient(#ffffff, #ffffff),
            linear-gradient(#6f58d9, #6f58d9);
          background-blend-mode: normal, normal;
          box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
          border-radius: 5px;
          position: absolute;
          right: 31px;
          top: 63px;
          z-index: 20000;

          img,
          .icon {
            display: inline-block;
            width: 25px;
            height: 25px;
          }

          span {
           font-family:Helvetica;
            font-size: 13px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #270645;
          }
        }
  }
  .asset{
	width: 100%;
	height: 126px;
	background-color: #270645;
	border-radius: 5px;
    .totalAsset{
      
        padding: 26px 41px 34px;
        .title{
          height: 12px;
	    font-family: Helvetica;
	    font-size: 15px;
        color: #ffffff;
        }
        .price{
            margin-top:17px;
            height: 32px;
	font-family: Helvetica;
	font-size: 35px;
    font-weight: bold;
    color: #ffdf7e;
	box-shadow: 0px 0px 9px 0px 
		rgba(0, 0, 0, 0.09);

        }
    }
   
  }
}
</style>