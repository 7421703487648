<template>
  <div id="container">
    <v-card :class="{ card: true, mobile: isMobile }">
      <img
        class="clo"
        @click="closeDialog"
        src="@/assets/btn_close.png"
        alt=""
      />
      <div class="contant">
        <div class="title">Disclaimer</div>
        <div class="explain">
          Coinswap is a decentralized peer-to-peer protocol that people can use
          to create liquidity and trade Cosmos-SDK based tokens. The Coinswap
          protocol is made up of free, public, and open-source software. Your
          use of Coinswap protocol involves various risks, including, but not
          limited, to losses while digital assets are being supplied to Coinswap
          protocol pools and losses due to the fluctuation of prices of tokens
          in a trading pair or liquidity pool, including Impermanence Loss.
          Before using any pool on the Coinswap protocol, you should review the
          relevant documentation to make sure you understand how Coinswap
          protocol works, and the pool you use on Coinswap protocol works.
          Additionally, just as you can access email protocols, such as SMTP,
          through multiple email clients, you can access pools on Coinswap
          protocol through several web or mobile interfaces. You are responsible
          for doing your own diligence on those interfaces to understand the
          fees and risks they present.<br /><br />
          AS DESCRIBED IN THE COINSWAP PROTOCOL LICENSES, THE COINSWAP PROTOCOL
          IS PROVIDED “AS IS”, AT YOUR OWN RISK, AND WITHOUT WARRANTIES OF ANY
          KIND. Coinswap protocol development teams do not provide, own, or
          control the Coinswap protocol, which is run by a decentralized
          validator set. Upgrades and modifications to the protocol are managed
          in a community-driven way by holders of the IRIS governance token. No
          developer or entity involved in creating the Coinswap protocol will be
          liable for any claims or damages whatsoever associated with your use,
          inability to use, or your interaction with other users of the Coinswap
          protocol, including any direct, indirect, incidental, special,
          exemplary, punitive or consequential damages, or loss of profits,
          cryptocurrencies, tokens, or anything else of value.<br /><br />
          And the Coinswap protocol is not being offered in China or to Chinese
          persons. The Coinswap protocol is not available to people or companies
          who are residents of, located, incorporated or have a registered agent
          in China. People or companies in restricted jurisdictions or embargoed
          by China are also prohibited from using the Coinswap protocol. Use of
          a virtual private network to circumvent the restrictions is also
          prohibited.
        </div>
        <div class="copyright box d-flex flex-row mb-1">
          <div class="checkbox mt-n5">
            <v-checkbox v-model="checkbox" label=""></v-checkbox>
          </div>
          <div class="content">
            By checking this box, I acknowledge that I have read, and do hereby
            accept the terms and conditions contained in this disclaimer.
          </div>
        </div>
        <div class="foot d-flex flex-row mb-7">
          <div class="btnbox">
            <button class="Decline" submit @click="DeclineCommit">
              Decline
            </button>
          </div>
          <div class="btnAgree ml-4">
            <button class="Agree" v-if="checkbox" submit @click="AgreeCommit">
              Agree
            </button>
            <button class="NoAgree" v-else submit>Agree</button>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>
export default {
  data: () => ({
    open: true,
    text: "",
    title: "",
    checkbox: "",
  }),
  props: {
    address: {
      type: String,
    },
    tokenId: {
      type: String,
    },
    type: {
      type: String,
    },
    offSaleTokenIds: {
      type: Array,
    },
    contractCollectionIds: {
      type: Number,
    },
    value: {
      type: Object,
    },
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  mounted() {
    window.eventBus.$on("LangChange", this.onLangChange);
  },
  methods: {
    onLangChange() {},
    //提交转送数据
    async DeclineCommit() {
      this.$router.go(-1);
      // console.log(res)
      // this.$refs.promptBox.show(res.msg);
    },
    async AgreeCommit() {
      window.localStorage.setItem("isReadmeNotice", false);
      this.open = false;
      this.$emit("getOpen", this.open);
      // console.log(res)
      // this.$refs.promptBox.show(res.msg);
    },
    //关闭弹框，发送open值
    closeDialog() {
      this.open = false;
      this.$emit("getOpen", this.open);
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  min-height: 274px;
  width: 730px;
  .contant {
    margin-left: 33px;
    margin-top: 30px;
    .title {
      font-family: Helvetica;
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
    }
    .explain {
      width: 662px;
      height: 433px;
      overflow-y: auto;
      font-family: Helvetica;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
    }
    .copyright {
      .content {
        width: 580px;
        font-family: Helvetica;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #270645;
      }
    }
  }
  .foot {
    margin-left: 340px;
    .btnbox {
      width: 151px;
      height: 41px;
      box-sizing: border-box;
      padding: 2px;
      background-image: linear-gradient(
        0deg,
        #d202fd 0%,
        #a538fe 26%,
        #776eff 51%,
        #01facd 100%
      );
      border-radius: 19px;
      .Decline {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 17px;
        background: #fff;
        font-family: Helvetica;
        font-size: 13px;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: 0;
        color: #270645;
      }
    }
    .btnAgree {
      .Agree {
        width: 151px;
        height: 41px;
        background-image: linear-gradient(
            90deg,
            #d300fd 0%,
            #a439fe 26%,
            #7471ff 52%,
            #00fdcf 100%
          ),
          linear-gradient(#270645, #270645);
        background-blend-mode: normal, normal;
        border-radius: 20px;
        color: #ffffff;
        font-family: Helvetica;
        font-size: 15px;
        font-weight: bold;
      }
      .NoAgree {
        width: 151px;
        height: 41px;
        background-image: linear-gradient(#766983, #766983),
          linear-gradient(#270645, #270645);
        border-radius: 20px;
        color: #ffffff;
        font-family: Helvetica;
        font-size: 15px;
        font-weight: bold;
      }
    }
  }
  .clo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 31px;
    background-color: #fff;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
  }

  &.mobile {
    .top {
      width: 100%;
      margin: 30px 10% 0;

      .content {
        width: 80%;
        height: auto;
        word-wrap: break-word;
      }
    }
    .footer {
      width: 100%;
      margin-left: 10%;
      .sub {
        width: 80%;
      }
    }
  }
}
</style>




