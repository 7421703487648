<template>
  <div id="container">
    <v-card :class="{ card: true, mobile: isMobile }">
      <img
        class="clo"
        @click="closeDialog"
        src="@/assets/btn_close.png"
        alt=""
      />
      <div class="top">
        <h3 class="end" v-if="assetType == 'Deposit'">
          {{ $t("DepositTitle") }}
        </h3>
        <h3 class="end" v-if="assetType == 'Withdraw'">
          {{ $t("WithdrawTitle") }}
        </h3>
        <h3 class="content">{{ $t("IBCTransfer") }}</h3>
      </div>
      <div class="address d-flex flex-row">
        <div class="left d-flex flex-column">
          <span>{{ $t("saledetailHistoryForm") }}</span>
          <v-text-field
            v-model="FromAddress"
            label=""
            outlined
            :disabled="true"
          ></v-text-field>
        </div>
        <div class="right d-flex flex-column">
          <span>{{ $t("saledetailHistoryTo") }}</span>
          <v-text-field
            v-model="ToAddress"
            label=""
            outlined
            :disabled="true"
          ></v-text-field>
        </div>
      </div>
      <div class="amountDeposit">{{ $t("AmountToDeposit") }}</div>
      <div class="balance">
        {{ $t("AvailableBalance") }}
         <span v-if="Balance">{{ Balance }} {{ this.assetChain }}</span>
        <span v-else :class="{'sub-lod': isPay}">{{$t('loadBalance')}} </span>
      </div>
      <div class="transactionAmount">
        <v-text-field
          v-model="transactionAmount"
          label=""
          outlined
          placeholder="0.0000"
          ref="SalePrice"
          :rules="[rules.errorPrice]"
          maxlength="8"
        ></v-text-field>
        <button class="sub" submit @click="maxCommit">
          {{ $t("AssetMax") }}
        </button>
      </div>
      <div class="footer">
        <div v-if="assetType == 'Deposit'">
          <button
            v-if="FromAddress && Balance && transactionAmount>0"
            class="sub"
            :class="{'sub-dis': isPay}"
            submit
            @click="subCommit"
          >
            {{ $t("Deposit") }}
          </button>
          <button
            v-else-if="FromAddress && !Balance"
            class="subdefect"
            submit
            @click="subCommit"
          >
            {{ $t("loadBalance") }}
          </button>
          <button v-else class="subdefect" :class="{'sub-dis': isPay}" submit>
            {{ $t("Deposit") }} 
          </button>
        </div>


        <div v-if="assetType == 'Withdraw'">
          <button
            v-if="FromAddress && Balance && transactionAmount>0" 
            class="sub"
             :class="{'sub-dis': isPay}"
            submit
            @click="subCommit"
          >
            {{ $t("Withdraw") }}
          </button>
            <button
            v-else-if="FromAddress && !Balance"
            class="subdefect"
            submit
            @click="subCommit"
          >
            {{ $t("loadBalance") }}
          </button>
          <button v-else class="subdefect" submit  :class="{'sub-dis': isPay}">
            {{ $t("Withdraw") }}
          </button>
        </div>
      </div>
    </v-card>
    <uComponents ref="ucom"></uComponents>
  </div>
</template>
<script>
// import api from "@/api";
import { init as keplrInit, getBalances} from "../../keplr/wallet";
import {
  ibcTransferFromCosmos,
  quiryTxCosmos,
} from "../../keplr/cosmos/wallet";

import { ibcTransferFromIris, quiryTx } from "../../keplr/iris/wallet";
import {
  ibcTransferFromGravity,
  quiryTxGravity,
} from "../../keplr/gravity/wallet";
    let UsdcBalance, AutomBalance;
export default {
  data: () => ({
    open: true,
    text: "",
    title: "",
    nameValue: "",
    FromAddress: "",
    FromChainAddress: "",
    ToAddress: "",
    ToChainAddress: "",
    transactionAmount: "",
    Balance: '',
    flag: true,
    isPay: false,
    rules: {
      errorPrice: (value) => {
        const pattern = /^((\+?[0-9][0-9]{0,8})\.([0-9]{1,6})$)|^(\+?[0-9][0-9]{0,8})$/;
        return pattern.test(value) || "verify price";
      },
    },
    txHash: "",
  }),
  props: {
    assetType: {
      type: String,
    },
    assetChain: {
      type: String,
    },
  },
  watch: {
    transactionAmount(newValue) {
      if (this.assetType == "Deposit") {
        if (newValue > (this.Balance * 1000000 - 3960) / 1000000) {

          let transactionAmount = (this.Balance * 1000000 - 3960) / 1000000 ;
          if(transactionAmount >0){
            this.transactionAmount = (this.Balance * 1000000 - 3960) / 1000000
          }else{
             this.transactionAmount = 0
             this.$toast("error", this.$t('popupAuctionDefectPriceInsufficientFunds'));
          }

        
        }
      } else {
        if (newValue >= Number(this.Balance)) {
          this.transactionAmount = this.Balance;
        }
      }
    },
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  async mounted() {


    // 获取钱包地址
   this.isPay = true
    let IrisTokenAddress = JSON.parse(localStorage.getItem("key_tokenAddress"));
    if(IrisTokenAddress){
        console.log(
      "wxl --- IrisTokenAddress",
      IrisTokenAddress.IrisAddress,
      IrisTokenAddress.USDCAddress,
      IrisTokenAddress.AtomAddress
    );
    if (this.assetType == "Deposit") {
      if (this.assetChain == "USDC") {
        this.FromChainAddress = IrisTokenAddress.USDCAddress;
        this.ToChainAddress = IrisTokenAddress.IrisAddress;
        this.FromAddress =
          IrisTokenAddress.USDCAddress.substr(0, 13) +
          "..." +
          IrisTokenAddress.USDCAddress.substr(-8);
        this.ToAddress =
          IrisTokenAddress.IrisAddress.substr(0, 13) +
          "..." +
          IrisTokenAddress.IrisAddress.substr(-8);
      } else if (this.assetChain == "ATOM") {
        this.FromChainAddress = IrisTokenAddress.AtomAddress;
        this.ToChainAddress = IrisTokenAddress.IrisAddress;

        this.FromAddress =
          IrisTokenAddress.AtomAddress.substr(0, 13) +
          "..." +
          IrisTokenAddress.AtomAddress.substr(-8);
        this.ToAddress =
          IrisTokenAddress.IrisAddress.substr(0, 13) +
          "..." +
          IrisTokenAddress.IrisAddress.substr(-8);
      }
    } else {
      if (this.assetChain == "USDC") {
        this.FromChainAddress = IrisTokenAddress.IrisAddress;
        this.ToChainAddress = IrisTokenAddress.USDCAddress;
        this.ToAddress =
          IrisTokenAddress.USDCAddress.substr(0, 13) +
          "..." +
          IrisTokenAddress.USDCAddress.substr(-8);
        this.FromAddress =
          IrisTokenAddress.IrisAddress.substr(0, 13) +
          "..." +
          IrisTokenAddress.IrisAddress.substr(-8);
      } else if (this.assetChain == "ATOM") {
        this.FromChainAddress = IrisTokenAddress.IrisAddress;
        this.ToChainAddress = IrisTokenAddress.AtomAddress;
        this.ToAddress =
          IrisTokenAddress.AtomAddress.substr(0, 13) +
          "..." +
          IrisTokenAddress.AtomAddress.substr(-8);
        this.FromAddress =
          IrisTokenAddress.IrisAddress.substr(0, 13) +
          "..." +
          IrisTokenAddress.IrisAddress.substr(-8);
      }
    }
    }
    //处理Deposit
    if (this.assetType == "Deposit") {
      if (this.assetChain == "USDC") {
           //  获取 Gravity 代币usdc的余额
    keplrInit(3);
    this.Usdcbalance = await getBalances();  
    console.log("wxl 12344555", this.Usdcbalance);
    if (this.Usdcbalance.balancesList) {
      for (let i = 0; i < this.Usdcbalance.balancesList.length; i++) {
        if (
          this.Usdcbalance.balancesList[i].denom ==
          "gravity0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48"
        ) {
          UsdcBalance = this.Usdcbalance.balancesList[i].amount / 1000000;
        }
      }
    }
        if (UsdcBalance) {
          
          this.Balance = UsdcBalance;
          this.isPay = false
        }else{
           this.Balance = '0';
          this.isPay = false
        }
      } else if (this.assetChain == "ATOM") {
         keplrInit(2);
    this.accountBalance = await getBalances();
    console.log("keplrInit(2);",this.accountBalance)
  
    if (this.accountBalance) {
      for (let i = 0; i < this.accountBalance.length; i++) {
        if (
          this.accountBalance[i].denom ==
          "ibc/A5A5EBF3B7A180FBA2A3CE133153F5B904AA8B085691F66A15BE8156B6722652"
        ) {
          UsdcBalance = this.accountBalance[i].amount / 1000000;
        } else if (this.accountBalance[i].denom == "uatom") {
          AutomBalance = this.accountBalance[i].amount / 1000000;
        } else {
          UsdcBalance = 0;
          AutomBalance = 0;
        }
      }
    }
        if (AutomBalance) {
          this.Balance = AutomBalance;
          this.isPay = false
        }else{
          this.Balance = '0';
          this.isPay = false
        }
      }
    } else {
       //  获取Iris链Token 余额
    keplrInit(1);
    this.irisAccountBalance = await getBalances();

    let Iris_IrisBalance, Iris_UsdcBalance, Iris_AutomBalance;
    console.log("Iris_IrisBalance",Iris_IrisBalance)

    if (this.irisAccountBalance.balancesList) {
      for (let i = 0; i < this.irisAccountBalance.balancesList.length; i++) {
        if (this.irisAccountBalance.balancesList[i].denom == "uiris") {
          Iris_IrisBalance =
            this.irisAccountBalance.balancesList[i].amount / 1000000;
        } else if (
          this.irisAccountBalance.balancesList[i].denom ==
          "ibc/27394FB092D2ECCD56123C74F36E4C1F926001CEADA9CA97EA622B25F41E5EB2"
        ) {
          Iris_AutomBalance =
            this.irisAccountBalance.balancesList[i].amount / 1000000;
        } else if (
          this.irisAccountBalance.balancesList[i].denom ==
          "ibc/A5A5EBF3B7A180FBA2A3CE133153F5B904AA8B085691F66A15BE8156B6722652"
        ) {
          Iris_UsdcBalance =
            this.irisAccountBalance.balancesList[i].amount / 1000000;
        }
      }
    }
      if (this.assetChain == "USDC") {
        if (Iris_UsdcBalance) {
          this.Balance = Iris_UsdcBalance;
          this.isPay = false
        }else{
          this.Balance = '0';
          this.isPay = false
        }
      } else if (this.assetChain == "ATOM") {
        if (Iris_AutomBalance) {
          this.Balance = Iris_AutomBalance;
          this.isPay = false
        }else{
           this.Balance = '0';
          this.isPay = false
        }
      }
    }
    window.eventBus.$on("LangChange", this.onLangChange);
  },
  methods: {
    onLangChange() {},
    verify() {
      let saleVer = this.$refs.SalePrice.validate(true);
      !saleVer ? this.$refs.SalePrice.focus() : "";
      return saleVer;
    },
    sleep(time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
    //提交转送数据
    async subCommit() {
      try {
          if (!this.verify()) {
        return;
      }
      this.isPay = true
      let result;
      let curTime = new Date().getTime();
      console.log("xxl current time is : " + curTime);
      let timespan = (curTime + 360000) * 1000000;
      if (this.assetType == "Deposit") {
        if (this.assetChain == "USDC") {
          // Gravity --> iris
          result = await ibcTransferFromGravity(
            "transfer",
            "channel-47",
            {
              denom: "gravity0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
              amount: (this.transactionAmount * 1000000).toString(),
            },
            this.ToChainAddress,
            timespan
          );
          this.txHash = result.txInfo.hash;
          this.isPay = false
          console.log("wxl ibcTransferFromGravity hash ", result, this.txHash);
        } else if (this.assetChain == "ATOM") {
          // ATOM -- > iris
          console.log(
            "uiris from cosoms to iris : ",
            this.transactionAmount * 1000000,
            this.ToChainAddress
          );
          result = await ibcTransferFromCosmos(
            "transfer",
            "channel-91",
            {
              denom: "uatom",
              amount: (this.transactionAmount * 1000000).toString(),
            },
            this.ToChainAddress,
            timespan
          );
          if(result) {
            this.txHash = result.transactionHash;
          }
          this.isPay = false
          console.log("wxl ---  ibcTransferFromIris", result);
        }
      } else {
        // iris -> Gravity
        if (this.assetChain == "USDC") {
          console.log("WithDrop Usdc ---- ", this.ToChainAddress);
          console.log("timespan :", timespan);
          result = await ibcTransferFromIris(
            "transfer",
            "channel-29",
            {
              denom:
                "ibc/A5A5EBF3B7A180FBA2A3CE133153F5B904AA8B085691F66A15BE8156B6722652",
              amount: this.transactionAmount * 1000000,
            },
            this.ToChainAddress,
            timespan
          );

          console.log("wxl ------- ibcTransferFromCosmos");
          this.txHash = result.txInfo.hash;
          this.isPay = false
          console.log("WithDrop", result);
        } else if (this.assetChain == "ATOM") {
          console.log("timespan :", timespan);
          // iris --> atom
          result = await ibcTransferFromIris(
            "transfer",
            "channel-0",
            {
              denom:
                "ibc/27394FB092D2ECCD56123C74F36E4C1F926001CEADA9CA97EA622B25F41E5EB2",
              amount: this.transactionAmount * 1000000,
            },
            this.ToChainAddress,
            timespan
          );

          console.log("wxl ------- ibcTransferFromCosmos");
        }
        if(result)
        this.txHash = result.txInfo.hash;
        this.isPay = false
        console.log("WithDrop", result);
      }

      // 判断是否交易成功
      if (this.txHash) {
        this.$mtip({
          title: this.$t("transactionInprogressSuccess"),
        });
      }
      this.$emit("getOpen", false);
      while (this.flag) {
        console.log("wwwwww");

        await this.sleep(5000).then(async () => {
          let res;
          if (this.assetType == "Deposit") {
            if (this.assetChain == "USDC") {
              res = await quiryTxGravity(this.txHash);
              console.log("wwwww ----- txhash", this.txHash);
            } else {
              res = await quiryTxCosmos(this.txHash);
            }
          } else {
            res = await quiryTx(this.txHash);
          }

          console.log("wxl -----  quiryTx");
          console.log(res);
          if (res.code == 0) {
            let title;
            if (this.assetType == "Deposit") {
          let t = this.$t("DepositSuccess")
					let reg=new RegExp('【N】','g')//g代表全部
					let regN=new RegExp('<br/>','g')//g代表全部
					let name = this.transactionAmount + this.assetChain
					 title=t.replace(reg,name).replace(regN," ");
            } else {
              title = this.$t("WithdrawSuccess");
            }

            this.$mtip({
              title: title,
            });
            this.flag = false;
          } else if (res.code == -1) {
            console.log("error");
            this.$mtip({
              title: res.log,
            });
            this.flag = false;
          } else {
            this.flag = true;
          }
        });
      }
        
      } catch (error) {
        console.log(error)
         this.$emit("getOpen", false);
      }
    
    },
    maxCommit() {
      this.transactionAmount = this.Balance;
    },

    //关闭弹框，发送open值
    closeDialog() {
      this.open = false;
      this.$emit("getOpen", this.open);
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  min-height: 200px;
  width: 600px;
  .top {
    display: flex;
    flex-direction: column;
    margin: 42px 51px 15px;
    .end {
      // width: 117px;
      // height: 20px;
      font-family: "\5FAE\8F6F\96C5\9ED1";
      font-size: 25px;
      font-weight: bold;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
    }

    .content {
      max-height: 72px;
      font-family: Helvetica;
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      letter-spacing: 0px;
      margin-top: 15px;
      color: #270645;
    }
  }
  .address {
    margin-left: 51px;
    .left {
      &::v-deep .v-input__slot {
        height: 56px !important;
        width: 241px !important;

        input[disabled],
        input:disabled,
        input.disabled {
          color: rgba(0, 0, 0, 0.87) !important;
        }
      }
      span {
        font-family: Helvetica;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;

        letter-spacing: 0px;
        color: #766983;
      }
    }
    .right {
      margin-left: 20px;
      &::v-deep .v-input__slot {
        height: 56px !important;
        width: 241px !important;
         input[disabled],
        input:disabled,
        input.disabled {
          color: rgba(0, 0, 0, 0.87) !important;
        }
      }
      span {
        font-family: Helvetica;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;

        letter-spacing: 0px;
        color: #766983;
      }
    }
  }
  .amountDeposit {
    margin-left: 51px;
    max-height: 72px;
    font-family: Helvetica;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    letter-spacing: 0px;
    margin-top: -15px;
    color: #270645;
  }
  .balance {
    margin-left: 51px;
    font-family: Helvetica;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 30px;
    letter-spacing: 0px;
    color: #7800f4;
  }
  .transactionAmount {
    margin-left: 51px;
    height: 50px;
    &::v-deep .v-input__slot {
      height: 55px !important;
      width: 500px !important;
    }
    .sub {
      position: relative;
      right: -440px;
      top: -70px;
      width: 53px;
      height: 28px;
      background-color: #000000;
      border-radius: 14px;

      font-family: Helvetica;
      font-size: 13px;
      color: #ffffff;
    }
  }
  .clo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 31px;
    background-color: #fff;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
  }
  .footer {
    margin: 0 auto;
    .sub {
      margin: 30px auto;
      width: 450px;
      height: 51px;
      background-image: linear-gradient(
          90deg,
          #d300fd 0%,
          #a439fe 26%,
          #7471ff 52%,
          #00fdcf 100%
        ),
        linear-gradient(#270645, #270645);
      background-blend-mode: normal, normal;
      border-radius: 25px;
      opacity: 0.9;
      font-family: "\5FAE\8F6F\96C5\9ED1";
      font-size: 20px;
      color: #ffffff;
    }
    .subdefect {
      margin: 30px auto;
      width: 450px;
      height: 51px;
      background-image: linear-gradient(#766983, #766983),
        linear-gradient(#270645, #270645);

      background-blend-mode: normal, normal;
      border-radius: 25px;
      opacity: 0.9;
      font-family: "\5FAE\8F6F\96C5\9ED1";
      font-size: 20px;
      color: #ffffff;
    }
  }
  &.mobile {
    .top {
      width: 100%;
      margin: 30px 10% 0;

      .content {
        width: 80%;
        height: auto;
        word-wrap: break-word;
      }
    }
    .footer {
      width: 100%;
      margin-left: 10%;
      .sub {
        width: 80%;
      }
    }
  }
}

img {
  display: inline-block;
  width: 126px;
  height: 126px;
  background-color: #8a73ff;
  border-radius: 5px;
  margin: 1px 22px 10px 52px;
}

.sub-dis {
    position: relative;
    pointer-events: none;
    background-image: linear-gradient(
      #766983, 
      #766983), 
     linear-gradient(
      #270645, 
      #270645) !important;
     background-blend-mode: normal, 
      normal;
     border-radius: 25px;
     opacity: 0.9;
}
.sub-dis::after {
    content: "";
    background-image: url(../../assets/loading.gif);
    background-size: 100%;
    display: inline-block;
    position: absolute;
    width: 20px;
    height: 20px;
    margin-left: 10px;
    margin-top: 5px;
    
   
}
.sub-lod {
    position: relative;
    pointer-events: none;
   
}
.sub-lod::after {
    content: "";
    background-image: url(../../assets/loading.gif);
    background-size: 100%;
    display: inline-block;
    position: absolute;
    width: 15px;
    height: 20px;
    margin-left: 10px;
    margin-top: 8px;
    
   
}
</style>




